export const BRANDS = {
  GVA: 'gva',
  HBVL: 'hbvl',
  DS: 'ds',
  DL: 'dl',
  NB: 'nb',
  LW: 'lw',
  CO: 'co',
  TC: 'tc',
  APORTAL: 'aportal',
};

export const MHLU_BRANDS = [BRANDS.LW, BRANDS.CO, BRANDS.TC, BRANDS.APORTAL];

export const ENTITIES = {
  MHBE: 'mhbe',
  MHLI: 'mhli',
  MHLU: 'mhlu',
};

export const ENTITY_MAP = {
  [BRANDS.GVA]: ENTITIES.MHBE,
  [BRANDS.HBVL]: ENTITIES.MHBE,
  [BRANDS.DS]: ENTITIES.MHBE,
  [BRANDS.DL]: ENTITIES.MHLI,
  [BRANDS.NB]: ENTITIES.MHBE,
  [BRANDS.LW]: ENTITIES.MHLU,
  [BRANDS.CO]: ENTITIES.MHLU,
  [BRANDS.TC]: ENTITIES.MHLU,
  [BRANDS.APORTAL]: ENTITIES.MHLU,
};

export const SHOP_NAMES = {
  [BRANDS.GVA]: 'GVA',
  [BRANDS.HBVL]: 'HBVL',
  [BRANDS.DS]: 'De Standaard',
  [BRANDS.DL]: 'De Limburger',
  [BRANDS.NB]: 'Nieuwsblad',
  [BRANDS.LW]: 'Luxemburger Wort',
  [BRANDS.CO]: 'Contacto',
  [BRANDS.TC]: 'Télécran',
};

export const SITE_URLS = {
  [BRANDS.GVA]: 'gva.be',
  [BRANDS.HBVL]: 'hbvl.be',
  [BRANDS.DS]: 'standaard.be',
  [BRANDS.DL]: 'limburger.nl',
  [BRANDS.NB]: 'nieuwsblad.be',
  [BRANDS.LW]: 'wort.lu',
  [BRANDS.CO]: 'contacto.lu',
  [BRANDS.TC]: 'telecran.lu',
  [BRANDS.APORTAL]: 'aportal.mediahuis.intern',
};

export const SITE_NAMES = {
  [BRANDS.GVA]: 'Gazet van Antwerpen',
  [BRANDS.HBVL]: 'Het Belang van Limburg',
  [BRANDS.DS]: 'De Standaard',
  [BRANDS.DL]: 'De Limburger',
  [BRANDS.NB]: 'het Nieuwsblad',
  [BRANDS.LW]: 'Luxemburger Wort',
  [BRANDS.CO]: 'Contacto',
  [BRANDS.TC]: 'Télécran',
  [BRANDS.APORTAL]: 'Agent portal',
};

export const LOGO_VERSIONS = {
  [BRANDS.GVA]: 'v2',
  [BRANDS.HBVL]: 'v2',
  [BRANDS.DS]: 'v2',
  [BRANDS.DL]: 'v1',
  [BRANDS.NB]: 'v2',
};

export const MOLLIE_IDS = {
  [BRANDS.GVA]: 'pfl_AaTsUVacFp',
  [BRANDS.HBVL]: 'pfl_NWhcrQTeVv',
  [BRANDS.DS]: 'pfl_RB7ExR4MB6',
  [BRANDS.DL]: 'pfl_vCMPre4ywW',
  [BRANDS.NB]: 'pfl_fwa7yuA2ez',
};

const MHBE_PRIVACY_URL = 'https://www.mediahuis.be/privacy-policy';

export const PRIVACY_URLS = {
  [BRANDS.GVA]: MHBE_PRIVACY_URL,
  [BRANDS.HBVL]: MHBE_PRIVACY_URL,
  [BRANDS.DS]: MHBE_PRIVACY_URL,
  [BRANDS.DL]: 'https://www.mediahuislimburg.nl/privacypolicy/',
  [BRANDS.NB]: MHBE_PRIVACY_URL,
  [BRANDS.LW]: 'https://www.wort.lu/de/privacy',
  [BRANDS.CO]: 'https://www.wort.lu/pt/privacy',
  [BRANDS.TC]: 'https://www.wort.lu/de/privacy',
};
