import React, { useEffect } from 'react';
import { Box, Select, Text } from '@mediahuis/chameleon-react-legacy';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import findIndex from 'lodash.findindex';
import {
  BANNER_TYPES,
  ENTITIES,
  ENTITY_MAP,
  PAGES,
  SUBSCRIPTION_SELECT_WIDTH,
  SUBSCRIPTION_STATUS_CODES,
} from '~/constants';
import { getSubscriptionScope, getUserSubscriptions } from '~/utils';
import { useFlag } from '@unleash/proxy-client-react';
import { config, ts } from '~/services';
import useBanner from '~/context/hooks/useBanner';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import { useSubscriptionContext } from '~/context';
import getOutstandingPayment from '~/pages/Subscription/utils/getOutstandingPayment';
import {
  FEATURE_FLAG_OUTSTANDING_PAYMENT_BRAND,
  FEATURE_FLAG_OUTSTANDING_PAYMENT_ENTITY,
  getFlagOutstandingPayment,
} from '~/constants/featureSwitch';
import { getUserName } from './utils';

const Caption = props => (
  <Text size="Caption2" display="block" fontFamily="system" {...props}>
    {ts.t('Subscription.SubscriptSelectHelpText', {
      richContent: true,
      values: { href: '#customer-service' },
    })}
  </Text>
);

const SubscriptionSelect = ({
  label = ts.t('Subscription.SubscriptionSelectLabel'),
}) => {
  const globalState = useGlobalContext();
  const subscriptionState = useSubscriptionContext();
  const {
    setGlobalState,
    userSubscriptions,
    userInfo,
    chosenUserSubscription,
  } = globalState;
  const { setSubscriptionState } = subscriptionState;

  const { showBanner } = useBanner();
  const { pathname } = useLocation();

  const outstandingPaymentsEnabledBrand = useFlag(
    FEATURE_FLAG_OUTSTANDING_PAYMENT_BRAND,
  );
  const outstandingPaymentsEnabledEntity = useFlag(
    FEATURE_FLAG_OUTSTANDING_PAYMENT_ENTITY,
  );

  const outstandingPaymentEnabledMHBE = useFlag(
    getFlagOutstandingPayment({ env: ENTITIES.MHBE, isEntity: true }),
  );
  const outstandingPaymentEnabledMHLI = useFlag(
    getFlagOutstandingPayment({ env: ENTITIES.MHLI, isEntity: true }),
  );

  const BRANDS_WITH_OUTSTANDING_PAYMENT_ACTIVE = Object.keys(ENTITY_MAP).filter(
    x =>
      (outstandingPaymentEnabledMHBE && ENTITY_MAP[x] === ENTITIES.MHBE) ||
      (outstandingPaymentEnabledMHLI && ENTITY_MAP[x] === ENTITIES.MHLI),
  );

  const outstandingPaymentsEnabled =
    outstandingPaymentsEnabledBrand || outstandingPaymentsEnabledEntity;

  const fetchOutstandingPayments = async ({ agreementId }) => {
    try {
      const payments = await getOutstandingPayment({
        agreementId,
      });
      const sortedPayments = payments.sort((a, b) => a.orderId - b.orderId);

      setSubscriptionState({
        outstandingPayments: sortedPayments,
        areOutstandingPaymentBannersVisible: true,
      });
    } catch (err) {
      setSubscriptionState({
        outstandingPayments: [],
        areOutstandingPaymentBannersVisible: false,
      });
    }
  };

  const shouldFetchOutstandingPayments = subscription => {
    return (
      outstandingPaymentsEnabled &&
      pathname.includes(PAGES.subscription) &&
      BRANDS_WITH_OUTSTANDING_PAYMENT_ACTIVE.includes(
        subscription?.subscriptionScopes?.brand,
      )
    );
  };

  // Get user subscriptions and then with user subscription internalSubscriptionType and brand get subscriptions scopes.
  useEffect(() => {
    const getUserSubscriptionsAndScopes = async () => {
      try {
        const userSubscriptionData = await getUserSubscriptions({
          accountGuid: userInfo.accountGuid,
        });
        const userSubscriptionDataWithScopes = await Promise.all(
          userSubscriptionData.map(async data => {
            try {
              const subscriptionScopes = await getSubscriptionScope({
                title: data.internalSubscriptionType,
                brand: data.brand,
              });
              const subscriptionWithScope = {
                ...data,
                subscriptionScopes,
              };
              // Label will be used for sorting and dropdown option
              subscriptionWithScope.label = `${getUserName(
                subscriptionWithScope,
              )} - ${subscriptionWithScope?.description} - ${
                subscriptionWithScope?.id
              }`;
              return Promise.resolve(subscriptionWithScope);
            } catch (error) {
              return Promise.resolve({
                ...data,
                subscriptionScopes: [],
              });
            }
          }),
        );
        const sortedStates = [
          SUBSCRIPTION_STATUS_CODES.ACTIVE,
          SUBSCRIPTION_STATUS_CODES.GRACE_PERIOD,
          SUBSCRIPTION_STATUS_CODES.WAITING,
          SUBSCRIPTION_STATUS_CODES.TEMPORARY_SUSPENSION,
          SUBSCRIPTION_STATUS_CODES.SUSPENDED,
        ];
        const sortedData = userSubscriptionDataWithScopes.sort((x, y) => {
          const xStateIndex = sortedStates.indexOf(x.state.toLowerCase());
          const yStateIndex = sortedStates.indexOf(y.state.toLowerCase());
          // Check if states are different
          if (xStateIndex < yStateIndex) {
            return -1; // X state has priority over Y state, put X before Y
          }
          if (yStateIndex < xStateIndex) {
            return 1; // Y state has priority over X state, put Y before X
          }

          // States are equal, check if brand is different
          const xIsCurrentBrand = x.brand === config.brand;
          const yIsCurrentBrand = y.brand === config.brand;
          if (xIsCurrentBrand && !yIsCurrentBrand) {
            return -1; // X is from the current brand, Y is not, put X before Y
          }
          if (!xIsCurrentBrand && yIsCurrentBrand) {
            return 1; // Y is from the current brand, X is not, put Y before X
          }
          return x.label < y.label ? -1 : 1; // Status and brand are equal, sort by label shown in dropdown
        });
        if (shouldFetchOutstandingPayments(sortedData[0])) {
          fetchOutstandingPayments({ agreementId: sortedData[0]?.id });
        }
        setGlobalState({
          userSubscriptions: sortedData,
          chosenUserSubscription: sortedData[0],
        });
      } catch (errors) {
        const { message, status } = errors;
        setGlobalState({
          userSubscriptions: '',
          chosenUserSubscription: '',
        });
        if (status !== 404) {
          showBanner({
            message,
            type: BANNER_TYPES.ERROR,
          });
        }
      }
    };
    if (isEmpty(userSubscriptions) && userInfo.accountGuid) {
      getUserSubscriptionsAndScopes();
    } else if (
      !isEmpty(userSubscriptions) &&
      shouldFetchOutstandingPayments(chosenUserSubscription)
    ) {
      fetchOutstandingPayments({ agreementId: chosenUserSubscription.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setGlobalState, userInfo.accountGuid, userSubscriptions]);

  const onChange = event => {
    const targetId = event.target.value;

    const chosenSubscriptionIndex = findIndex(
      userSubscriptions,
      subscr => subscr.id === targetId,
    );

    setGlobalState({
      chosenUserSubscription: userSubscriptions[chosenSubscriptionIndex],
    });

    if (
      shouldFetchOutstandingPayments(userSubscriptions[chosenSubscriptionIndex])
    ) {
      fetchOutstandingPayments({ agreementId: targetId });
    } else {
      setSubscriptionState &&
        setSubscriptionState({
          outstandingPayments: [],
          areOutstandingPaymentBannersVisible: false,
        });
    }
  };

  return (
    <React.Fragment>
      {userSubscriptions?.length > 1 && (
        <Box mb={5} maxWidth={SUBSCRIPTION_SELECT_WIDTH}>
          <Select
            label={label}
            id="subscriptionSelect"
            value={chosenUserSubscription && chosenUserSubscription.id}
            onChange={onChange}
            data-testid="user-subscription-select"
          >
            {userSubscriptions &&
              userSubscriptions.map(userSubscription => {
                return (
                  userSubscription.id && (
                    <option
                      key={userSubscription.id}
                      value={userSubscription.id}
                    >
                      {userSubscription.label}
                    </option>
                  )
                );
              })}
          </Select>
        </Box>
      )}
      {chosenUserSubscription && <Caption mb={5} />}
    </React.Fragment>
  );
};

export default SubscriptionSelect;
