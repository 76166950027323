import { Flex, Text } from '@mediahuis/chameleon-react-legacy';
import dayjs from 'dayjs';
import isEmpty from 'lodash.isempty';
import getAddressChanges from '~/api/ah/getAddressChanges';
import WideListItemAction from '~/components/WideListItemAction';
import getFirstUpcomingAddressChange from '~/components/ah/AhSubscriptionSelect/util/getFirstUpcomingAddressChange';
import {
  BANNER_TYPES,
  DATE_FORMAT_BE,
  DATE_FORMAT_READABLE,
} from '~/constants';
import { MODIFIED_STATE } from '~/pages/Subscription/ah/aportal/ReaderPayer';
import {
  getAddressField,
  getFullNameOfCustomerField,
} from '~/pages/Subscription/ah/utils/fields';
import { ROLES } from '~/pages/Subscription/constants';
import { ts } from '~/services';
import { addLeadingZeroes } from '~/utils/addLeadingZeroes';
import removeLeadingZeroes from '~/utils/removeLeadingZeroes';

export const determineIsAddressAllowed = ({
  countryCode,
  postalCode,
  allowedCountries,
}) => {
  const isCountryNotAllowed =
    isEmpty(allowedCountries) || !allowedCountries[countryCode];

  if (isCountryNotAllowed) {
    return false;
  }
  const customerAllowedCountry = allowedCountries[countryCode];

  const isCustomerPostalCodeAllowed =
    customerAllowedCountry.allowAllPostalCodes ||
    customerAllowedCountry.allowedPostalCodes.includes(postalCode);

  return isCustomerPostalCodeAllowed;
};

export const getCustomerIdsToFetch = ({
  roleChanges,
  currnetPayerId,
  currentReceiverId,
}) => {
  const allCustomerIds = roleChanges
    .map(({ nextCustomerId, previousCustomerId }) => [
      nextCustomerId,
      previousCustomerId,
    ])
    .flat(Infinity);
  const filteredCustomerids = allCustomerIds.filter(
    (customerId, index) =>
      allCustomerIds.indexOf(customerId) === index &&
      customerId !== currnetPayerId &&
      customerId !== currentReceiverId,
  );
  return filteredCustomerids;
};
export const formatAffectedRoles = (affectedRoles = []) => {
  const payerIsAffected = affectedRoles.includes(ROLES.BILL_TO);
  const readerIsAffected = affectedRoles.includes(ROLES.SHIP_TO);
  const payerAndReaderAreAffected = payerIsAffected && readerIsAffected;

  if (payerAndReaderAreAffected) {
    return ts.t('Subscription.PayerReader');
  }
  if (payerIsAffected) {
    return ts.t('Subscription.Payer');
  }
  return ts.t('Subscription.Reader');
};

const renderCustomerDetail = customer => {
  return (
    <Flex flexDirection="column">
      <Text size="Caption1" decoration="underline">
        {removeLeadingZeroes(customer.id)}
      </Text>
      <Text size="Caption1">{getFullNameOfCustomerField(customer)}</Text>
      <Text size="Caption1">
        {getAddressField(customer?.addresses?.nonStandard?.[0])}
      </Text>
    </Flex>
  );
};

export const mapRoleChanges = ({
  roleChanges,
  isReadOnlyMode,
  allCustomers,
  handleCancel,
}) => {
  return roleChanges
    .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
    .map(roleChange => {
      const {
        startDate,
        canDelete,
        nextCustomerId,
        previousCustomerId,
        affectedRoles,
        delivery,
      } = roleChange;
      const nextCustomer = allCustomers.find(
        customer => customer.id === nextCustomerId,
      );
      const previousCustomer = allCustomers.find(
        customer => customer.id === previousCustomerId,
      );
      return {
        startDate: dayjs(startDate).format(DATE_FORMAT_READABLE),
        role: formatAffectedRoles(affectedRoles),
        nextCustomer: renderCustomerDetail(nextCustomer),
        previousCustomer: renderCustomerDetail(previousCustomer),
        deliveryType: delivery?.detail?.label,
        cancel:
          !isReadOnlyMode && canDelete ? (
            <WideListItemAction
              handleClick={() => handleCancel(roleChange)}
              label={ts.t('Common.Cancel')}
            />
          ) : (
            ' '
          ),
      };
    });
};

export const getRoleChangeBody = ({
  customerNumber,
  startDate,
  deliveryMethod,
  modifiedPerson,
}) => {
  const body = {
    nextCustomerId: addLeadingZeroes(customerNumber),
    startDate: dayjs(startDate).format(DATE_FORMAT_BE),
    rolesToChange: [],
    ...(deliveryMethod && {
      changeSubscriptionDelivery: { typeCode: deliveryMethod },
    }),
  };

  if (modifiedPerson === MODIFIED_STATE.READER) {
    body.rolesToChange.push(ROLES.SHIP_TO);
  }
  if (modifiedPerson === MODIFIED_STATE.PAYER) {
    body.rolesToChange.push(ROLES.BILL_TO);
  } else if (modifiedPerson === MODIFIED_STATE.READER_AND_PAYER) {
    body.rolesToChange.push(ROLES.SHIP_TO);
    body.rolesToChange.push(ROLES.BILL_TO);
  }
  return body;
};

export const fetchPayerAndReceiverAddressChanges = async ({
  setIsLoadingAddressChanges,
  payer,
  receiver,
  isPayerEqualToReceiver,
  setNewReceiverAddressChange,
  setNewPayerAddressChange,
  showBanner,
}) => {
  try {
    setNewPayerAddressChange(null);
    setNewReceiverAddressChange(null);
    setIsLoadingAddressChanges(true);
    const requests = [
      getAddressChanges({
        customerId: payer?.id,
      }),
    ];

    if (receiver && !isPayerEqualToReceiver) {
      requests.push(
        getAddressChanges({
          customerId: receiver?.id,
        }),
      );
    }

    const [payerAddressChanges, receiverAddressChanges] = await Promise.all(
      requests,
    );

    if (isPayerEqualToReceiver) {
      setNewReceiverAddressChange(
        getFirstUpcomingAddressChange(payerAddressChanges),
      );
    } else {
      if (receiver) {
        setNewReceiverAddressChange(
          getFirstUpcomingAddressChange(receiverAddressChanges),
        );
      }
      setNewPayerAddressChange(
        getFirstUpcomingAddressChange(payerAddressChanges),
      );
    }
  } catch (error) {
    showBanner({
      message: ts.t('Subscriptions.Errors.AddressChanges'),
      type: BANNER_TYPES.ERROR,
    });
  } finally {
    setIsLoadingAddressChanges(false);
  }
};
