import React, { useEffect } from 'react';
import { Box, Select, Text } from '@mediahuis/chameleon-react-legacy';
import findIndex from 'lodash.findindex';
import { BANNER_TYPES, SUBSCRIPTION_SELECT_WIDTH } from '~/constants';
import getUserSubscriptions from '~/api/ah/getUserSubscriptions';
import { ts } from '~/services';
import useBanner from '~/context/hooks/useBanner';
import getModuleRightsByIdentityId from '~/api/ah/getModuleRightsByIdentityId';
import useGlobalContext from '~/context/hooks/useGlobalContext';
import removeLeadingZeroes from '~/utils/removeLeadingZeroes';
import getSortedUserSubscriptions from './util/getSortedUserSubscriptions';
import getPayerAndReceiver from './util/getPayerAndReceiver';

const Caption = props => (
  <Text size="Caption2" display="block" fontFamily="system" {...props}>
    {ts.t('Subscription.SubscriptSelectHelpText', {
      richContent: true,
      values: { href: '#customer-service' },
    })}
  </Text>
);

const AhSubscriptionSelect = ({
  label = ts.t('Subscription.SubscriptionSelectLabel'),
}) => {
  const globalState = useGlobalContext();
  const {
    setGlobalState,
    userSubscriptions,
    userInfo,
    chosenUserSubscription,
    hasFetchedUserSubscriptions,
  } = globalState;
  const { showBanner } = useBanner();

  const fetchData = async () => {
    let sortedData = [];
    let subscriptionWithPayerAndReceiver;
    let userModuleRights;
    let hasErrorWhenFetchingSubscriptions = false;

    try {
      const userSubscriptionData = await getUserSubscriptions({
        accountGuid: userInfo.accountGuid,
      });

      const userSubscriptionDataWithLabel = userSubscriptionData.map(x => {
        x.label = `${x?.name} - ${removeLeadingZeroes(x?.id)}`;
        return x;
      });
      sortedData = getSortedUserSubscriptions(userSubscriptionDataWithLabel);

      subscriptionWithPayerAndReceiver = await getPayerAndReceiver(
        sortedData[0],
      );
    } catch (errors) {
      hasErrorWhenFetchingSubscriptions = true;
      showBanner({
        message: ts.t('Subscription.ErrorMessage.General'),
        type: BANNER_TYPES.ERROR,
      });
    }
    try {
      userModuleRights = await getModuleRightsByIdentityId({
        accountGuid: userInfo.accountGuid,
      });
    } catch (errors) {
      if (!hasErrorWhenFetchingSubscriptions) {
        showBanner({
          message: ts.t('Subscription.ErrorMessage.ModuleRights'),
          type: BANNER_TYPES.ERROR,
        });
      }
    }
    setGlobalState({
      userSubscriptions: sortedData,
      chosenUserSubscription: subscriptionWithPayerAndReceiver,
      userModuleRights,
      hasFetchedUserSubscriptions: true,
    });
  };

  useEffect(() => {
    if (!hasFetchedUserSubscriptions && userInfo.accountGuid) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.accountGuid, hasFetchedUserSubscriptions]);

  const onChange = async event => {
    try {
      const targetId = event.target.value;

      const chosenSubscriptionIndex = findIndex(
        userSubscriptions,
        subscr => subscr.id === targetId,
      );
      setGlobalState({
        isLoadingPayerAndReceiver: true,
      });

      const selectedSubscriptionWithPayerAndReceiver = await getPayerAndReceiver(
        userSubscriptions[chosenSubscriptionIndex],
      );
      setGlobalState({
        chosenUserSubscription: selectedSubscriptionWithPayerAndReceiver,
        isLoadingPayerAndReceiver: false,
      });
    } catch (error) {
      console.log(error);
      setGlobalState({
        isLoadingPayerAndReceiver: false,
      });
    }
  };

  return (
    <>
      {userSubscriptions?.length > 1 && (
        <Box mb={5} maxWidth={SUBSCRIPTION_SELECT_WIDTH}>
          <Select
            label={label}
            id="subscriptionSelect"
            value={chosenUserSubscription && chosenUserSubscription.id}
            onChange={onChange}
            data-testid="user-subscription-select"
          >
            {userSubscriptions &&
              userSubscriptions.map(userSubscription => {
                return (
                  userSubscription.id && (
                    <option
                      key={userSubscription.id}
                      value={userSubscription.id}
                    >
                      {userSubscription.label}
                    </option>
                  )
                );
              })}
          </Select>
        </Box>
      )}
      {chosenUserSubscription && <Caption mb={5} />}
    </>
  );
};

export default AhSubscriptionSelect;
