import {
  Box,
  Flex,
  Heading,
  LinkText,
  Loader,
  WideList,
  WideListItem,
} from '@mediahuis/chameleon-react-legacy';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Dialog } from '~/components';
import { getCustomerRights } from '~/components/ah/AhSubscriptionSelect/util/rights';
import { DATE_FORMAT_READABLE } from '~/constants';
import { useBanner, useGlobalContext } from '~/context';
import ContactDetailsSection from '~/pages/Subscription/ah/ContactDetailsSection';
import { fetchPayerAndReceiverAddressChanges } from '~/pages/Subscription/ah/utils';
import DialogContent from '~/pages/Subscription/components/DialogContent';
import { ts } from '~/services';
import { scrollTopWithTimeout } from '~/utils';
import removeLeadingZeroes from '~/utils/removeLeadingZeroes';
import { AH_SUBSCRIPTION_STATUS, DIALOG_TYPES } from '../constants';
import {
  getAddressField,
  getEndDateField,
  getFullNameOfCustomerField,
} from './utils/fields';

const AhSubscriptionContent = ({ setRefreshAddressChangesHasFailed }) => {
  const {
    chosenUserSubscription,
    userModuleRights,
    isLoadingPayerAndReceiver,
  } = useGlobalContext();
  const [dialogShown, setDialogShown] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editPayerPhoneNumbers, setEditPayerPhoneNumbers] = useState(false);
  const [newReceiverAddressChange, setNewReceiverAddressChange] = useState(
    null,
  );
  const [newPayerAddressChange, setNewPayerAddressChange] = useState(null);
  const [isLoadingAddressChanges, setIsLoadingAddressChanges] = useState(true);
  const [
    selectedAddressChangeToCancel,
    setSelectedAddressChangeToCancel,
  ] = useState(null);

  const { showBanner } = useBanner();

  const receiver = chosenUserSubscription?.receiver;
  const payer = chosenUserSubscription?.payer;
  const RECEIVER_ADDRESS = getAddressField(
    receiver?.addresses?.nonStandard?.[0],
  );
  const NEW_RECEIVER_ADDRESS = getAddressField(
    newReceiverAddressChange?.addresses?.nonStandard?.[0],
  );
  const PAYER_ADDRESS = getAddressField(payer?.addresses?.nonStandard?.[0]);
  const NEW_PAYER_ADDRESS = getAddressField(
    newPayerAddressChange?.addresses?.nonStandard?.[0],
  );

  const RECEIVER_NAME = getFullNameOfCustomerField(receiver);
  const PAYERNAME = getFullNameOfCustomerField(payer);
  const END_DATE = getEndDateField(chosenUserSubscription);

  const isPayerEqualToReceiver = receiver?.id === payer?.id;

  useEffect(() => {
    fetchPayerAndReceiverAddressChanges({
      setIsLoadingAddressChanges,
      isPayerEqualToReceiver,
      payer,
      receiver,
      setNewPayerAddressChange,
      setNewReceiverAddressChange,
      showBanner,
    });
  }, [chosenUserSubscription]);

  const handleClick = ({ type, payerReaderStatus, selectedAddressChange }) => {
    setDialogType(type);
    setDialogShown(true);
    setEditPayerPhoneNumbers(payerReaderStatus);
    if (selectedAddressChange) {
      setSelectedAddressChangeToCancel(selectedAddressChange);
    }
  };

  const hasPayerRightToChangeAddress = getCustomerRights({
    customerId: payer?.id,
    customersRights: userModuleRights?.customers,
  })?.addressChange?.access;

  const hasReceiverRightToChangeAddress = getCustomerRights({
    customerId: receiver?.id,
    customersRights: userModuleRights?.customers,
  })?.addressChange?.access;

  if (isLoadingPayerAndReceiver) {
    return (
      <Flex height="500px" justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );
  }
  return (
    <>
      <Box mb={8}>
        <Box mb={6} mt={8}>
          <Heading level={3}>{ts.t('Subscription.General')}</Heading>
        </Box>
        <Box>
          <WideList>
            <WideListItem
              title={ts.t('Subscription.Type')}
              loading={!chosenUserSubscription}
              description={chosenUserSubscription?.name}
            />
            <WideListItem
              title={ts.t('Subscription.Number')}
              loading={!chosenUserSubscription}
              description={removeLeadingZeroes(chosenUserSubscription?.id)}
            />
            {(receiver || isPayerEqualToReceiver) && (
              <>
                <WideListItem
                  title={ts.t('Subscription.DeliveryAddress', {
                    values: {
                      NEW_ADDRESS: NEW_RECEIVER_ADDRESS
                        ? ` - ${ts.t('Subscription.Current')}`
                        : '',
                    },
                  })}
                  description={
                    <>
                      {RECEIVER_NAME && (
                        <>
                          {RECEIVER_NAME}
                          <br />
                        </>
                      )}
                      {RECEIVER_ADDRESS}
                    </>
                  }
                  action={
                    hasReceiverRightToChangeAddress && (
                      <LinkText
                        onClick={() =>
                          handleClick({
                            type: DIALOG_TYPES.ADDRESS,
                          })
                        }
                      >
                        {ts.t('Common.Edit')}
                      </LinkText>
                    )
                  }
                />
                {(NEW_RECEIVER_ADDRESS || isLoadingAddressChanges) && (
                  <WideListItem
                    title={ts.t('Subscription.DateFrom', {
                      values: {
                        dateFrom: dayjs(
                          newReceiverAddressChange?.startDate,
                        ).format(DATE_FORMAT_READABLE),
                      },
                    })}
                    description={NEW_RECEIVER_ADDRESS}
                    loading={isLoadingAddressChanges}
                    action={
                      hasReceiverRightToChangeAddress &&
                      newReceiverAddressChange?.canDelete && (
                        <LinkText
                          onClick={() =>
                            handleClick({
                              type: DIALOG_TYPES.CANCEL_RECEIVER_ADDRESS_CHANGE,
                              selectedAddressChange: newReceiverAddressChange,
                            })
                          }
                        >
                          {ts.t('Common.Cancel')}
                        </LinkText>
                      )
                    }
                  />
                )}
              </>
            )}
            {/* Show payer address when there is no receiver or when the payer and receiver are not equal */}
            {!isPayerEqualToReceiver && (
              <>
                <WideListItem
                  title={ts.t('Subscription.InvoiceAddress', {
                    values: {
                      NEW_ADDRESS: NEW_PAYER_ADDRESS
                        ? ` - ${ts.t('Subscription.Current')}`
                        : '',
                    },
                  })}
                  description={
                    <>
                      {PAYERNAME && (
                        <>
                          {PAYERNAME}
                          <br />
                        </>
                      )}
                      {PAYER_ADDRESS}
                    </>
                  }
                  action={
                    hasPayerRightToChangeAddress && (
                      <LinkText
                        onClick={() =>
                          handleClick({
                            type: DIALOG_TYPES.ADDRESS_INVOICE,
                          })
                        }
                      >
                        {ts.t('Common.Edit')}
                      </LinkText>
                    )
                  }
                />
                {(NEW_PAYER_ADDRESS || isLoadingAddressChanges) && (
                  <WideListItem
                    title={ts.t('Subscription.PayerAddressDateFrom', {
                      values: {
                        dateFrom: dayjs(
                          newPayerAddressChange?.startDate,
                        ).format(DATE_FORMAT_READABLE),
                      },
                    })}
                    description={NEW_PAYER_ADDRESS}
                    loading={isLoadingAddressChanges}
                    action={
                      hasPayerRightToChangeAddress &&
                      newPayerAddressChange?.canDelete && (
                        <LinkText
                          onClick={() =>
                            handleClick({
                              type: DIALOG_TYPES.CANCEL_PAYER_ADDRESS_CHANGE,
                              selectedAddressChange: newPayerAddressChange,
                            })
                          }
                        >
                          {ts.t('Common.Cancel')}
                        </LinkText>
                      )
                    }
                  />
                )}
              </>
            )}
            {chosenUserSubscription?.expirationSubscriptionDate && (
              <WideListItem
                title={ts.t('Subscription.EndDate')}
                loading={!chosenUserSubscription}
                description={END_DATE}
              />
            )}

            <WideListItem
              title={ts.t('Subscription.State')}
              loading={!chosenUserSubscription}
              description={
                AH_SUBSCRIPTION_STATUS[chosenUserSubscription?.lifeCycle]
              }
            />
          </WideList>
        </Box>
      </Box>

      <Box mb={6}>
        <Heading level={3}>{ts.t('Subscription.PaymentHeading')}</Heading>
      </Box>
      <Box mb={8}>
        <WideList>
          <WideListItem
            title={ts.t('Subscription.PaymentMethod')}
            loading={!chosenUserSubscription}
            description={
              chosenUserSubscription?.nextPaymentMethod?.method?.label
            }
          />
          {chosenUserSubscription?.nextPaymentMethod?.bankAccount && (
            <WideListItem
              title={ts.t('Subscription.PaymentInfo')}
              loading={!chosenUserSubscription}
              description={
                chosenUserSubscription?.nextPaymentMethod?.bankAccount
              }
            />
          )}
        </WideList>
      </Box>
      <ContactDetailsSection handleClick={handleClick} />
      {dialogShown && (
        <Dialog
          show={dialogShown}
          onClose={() => {
            setDialogShown(false);
            scrollTopWithTimeout();
          }}
          closeOnBackdropClick={false}
        >
          <DialogContent
            dialogType={dialogType}
            setDialogShown={setDialogShown}
            editPayerPhoneNumbers={editPayerPhoneNumbers}
            dialogShown={dialogShown}
            setNewReceiverAddress={setNewReceiverAddressChange}
            setNewPayerAddress={setNewPayerAddressChange}
            setRefreshAddressChangesHasFailed={
              setRefreshAddressChangesHasFailed
            }
            selectedAddressChangeToCancel={selectedAddressChangeToCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default AhSubscriptionContent;
