import { BRANDS } from '~/constants';
import { EMAIL, STATUSES, CHAT, PHONE } from '../enums';

const getPhoneNumberPrefix = () => {
  switch (MH_BRAND) {
    case BRANDS.DL:
      return '31';
    case BRANDS.LW:
    case BRANDS.CO:
    case BRANDS.TC:
      return '352';
    default:
      return '32';
  }
};
const buildContactItem = ({ data }) => {
  const { CLOSED, OPEN } = STATUSES;
  let values = {};
  if (data.settings.visible) {
    if (data.output.type === EMAIL && data.output.status === CLOSED) {
      values = { ...values, mayShow: false };
    } else if (
      data.output.type === EMAIL &&
      data.output.status === OPEN &&
      data.output.subscriptionpage
    ) {
      values = { ...values, mayShow: false };
    } else {
      // Show the contact element
      values = {
        ...values,
        mayShow: true,
        mainText: data.output.display.maintext,
        editText: data.output.display.edittext,
      };
    }
  } else {
    // Hide the contact element
    values = { ...values, mayShow: false };
  }

  switch (data.output.type) {
    case CHAT:
      if (data.output.subscriptionpage) {
        values = {
          ...values,
          link: data.contactdetails.subscriptionchat_link,
        };
      } else {
        values = {
          ...values,
          link: data.contactdetails?.chat_link,
        };
      }

      if (data.output.status === 'OPEN') {
        values = {
          ...values,
          bChatActive: true,
        };
      }
      break;
    case EMAIL:
      values = {
        ...values,
        link: data.settings.emaillink,
      };
      break;
    case PHONE:
      if (data.output.subscriptionpage) {
        values = {
          ...values,
          link: `tel:+${
            getPhoneNumberPrefix()
            // Remove first character and remove all white spaces.
          }${data.settings.subscriptiontelnr.slice(1).replace(/ /g, '')}`,
        };
      } else {
        values = {
          ...values,
          link: `tel:+${
            getPhoneNumberPrefix()
            // Remove first character and remove all white spaces.
          }${data.settings.telnr?.slice(1).replace(/ /g, '')}`,
        };
      }
      break;
    default:
      break;
  }
  return values;
};

export default buildContactItem;
