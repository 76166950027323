import React from 'react';
import { Box } from '@mediahuis/chameleon-react-legacy';
import {
  colorPrimaryBase,
  colorSecondaryBase,
} from '@mediahuis/chameleon-theme-wl/legacy/web/tokens.js';
import Icons from '~/svg/Icons';
import { BRANDS } from '~/constants';
import { config } from '~/services';

const SIZE = '30px';

export const ICON_FILL = {
  [BRANDS.GVA]: colorPrimaryBase,
  [BRANDS.DL]: colorPrimaryBase,
  [BRANDS.NB]: colorPrimaryBase,
  [BRANDS.HBVL]: colorSecondaryBase,
  [BRANDS.DS]: colorPrimaryBase,
  [BRANDS.LW]: colorPrimaryBase,
  [BRANDS.CO]: colorPrimaryBase,
  [BRANDS.TC]: colorPrimaryBase,
  [BRANDS.APORTAL]: colorSecondaryBase,
};

const Icon = ({ name, style, width = SIZE, height = SIZE, ...props }) => {
  const SelfHelpIcon = Icons[name];
  return (
    <Box
      width={width}
      height={height}
      style={{ fill: ICON_FILL[config.brand], ...style }}
      {...props}
    >
      <SelfHelpIcon />
    </Box>
  );
};

export default Icon;
