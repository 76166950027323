import {
  SITE_URLS,
  SITE_NAMES,
  LOGO_VERSIONS,
  SHOP_NAMES,
  BRANDS,
  MOLLIE_IDS,
  ENTITIES,
  ENTITY_MAP,
  PRIVACY_URLS,
  MHLU_BRANDS,
} from '~/constants/config';
import { environment } from '~/services';

/**
 * @typedef SRConfig
 * @property {string} siteUrl
 * @property {string} brandNameFull
 * @property {string} logoVersion
 * @property {string} urlPrefix
 * @property {string} apiPrefix
 */

/**
 * @class ConfigService
 */
class ConfigService {
  #brand = '';
  #theme = '';

  helloCustomer = {
    delay: 1500,
    quarantineDays: 30,
  };

  constructor() {
    this.#brand = MH_BRAND;
    this.#theme = MH_THEME;
  }

  get apiSubscriptionScopeUrl() {
    return (
      this.mockApiUrl ||
      `https://${environment.apiPrefix}subscriptionscope.mediahuis.be/api`
    );
  }

  get brand() {
    return this.#brand;
  }

  get entity() {
    return ENTITY_MAP[this.#brand];
  }

  get theme() {
    return this.#theme;
  }

  get siteUrl() {
    return SITE_URLS[this.#brand];
  }

  get brandNameFull() {
    return SITE_NAMES[this.#brand];
  }

  get logoVersion() {
    return LOGO_VERSIONS[this.#brand];
  }

  get subscriptionServiceUrl() {
    return `https://${environment.apiPrefix}subscriptionservice.${this.siteUrl}`;
  }

  get eloketServiceUrl() {
    return `https://${environment.apiPrefix}klantenservice.${this.siteUrl}`;
  }

  get eloketUrl() {
    const mhluEloketUrl = `https://${
      environment.isProd ? '' : environment.sitePrefix
    }service.${this.siteUrl}`;

    return ENTITY_MAP[this.#brand] === ENTITIES.MHLU
      ? mhluEloketUrl
      : `https://${environment.sitePrefix}klantenservice.${this.siteUrl}`;
  }

  getLocationValidatorUrl = brand => {
    return brand === BRANDS.DL
      ? `https://${environment.apiPrefix}locationvalidator.limburger.nl/api/DeliveryArea?brand=${brand}`
      : `https://${environment.apiPrefix}locationvalidator.mediahuis.be/api/DeliveryArea?brand=${brand}`;
  };

  get aboshopUrl() {
    return `https://${environment.apiPrefix}aboshop.${this.siteUrl}`;
  }

  get baseUrl() {
    return `https://${this.siteUrl}`;
  }

  get shopUrl() {
    return `https://shop.${this.siteUrl}`;
  }

  get privacyUrl() {
    return PRIVACY_URLS[this.#brand];
  }

  get outstandingPaymentApiUrl() {
    return (
      this.mockApiUrl ||
      `https://${environment.apiPrefix}ordersettleservice.${this.siteUrl}/api`
    );
  }

  get featureSwithUrl() {
    return `https://${environment.apiPrefix}customercare-featureflags.mediahuis.be/proxy`;
  }

  get addressServiceApiUrl() {
    return `https://${environment.apiPrefix}addressservice.mediahuis.be`;
  }

  get serviceApiUrl() {
    return `https://${environment.serviceApiPrefix}serviceapi.${this.siteUrl}/api`;
  }

  /*
   * APortal dev: https://devserviceapi.mediahuis.intern/b2c/subscriptionmanagement-mhlu/v1/api
   * Branded dev: https://devserviceapi.mediahuis.lu/b2c/subscriptionmanagement-mhlu/v1/api
   * APortal test: https://testserviceapi.mediahuis.intern/b2c/subscriptionmanagement-mhlu/v1/api
   * ...
   */
  get ahServiceApiUrl() {
    return (
      this.mockApiUrl ||
      `https://${environment.serviceApiPrefix}serviceapi.mediahuis.${
        this.#brand === BRANDS.APORTAL ? 'intern' : 'lu'
      }/b2c/subscriptionmanagement-mhlu/v1/api`
    );
  }

  get accountManagementApiUrl() {
    return (
      this.mockApiUrl ||
      `https://${environment.apiPrefix}accountmanagement.${this.siteUrl}/api`
    );
  }

  get errorContactUrl() {
    return `https://${environment.sitePrefix}.${this.siteUrl}/service`;
  }

  get newsSiteUrl() {
    return `https://www.${this.siteUrl}`;
  }

  get shopName() {
    return SHOP_NAMES[this.#brand];
  }

  get siteName() {
    return SITE_NAMES[this.#brand];
  }

  get mollieId() {
    return MOLLIE_IDS[this.brand];
  }

  get isMHLU() {
    return this.entity === ENTITIES.MHLU;
  }

  get isAportal() {
    return this.#brand === BRANDS.APORTAL;
  }

  /** @type {SRConfig} */
  get srConfig() {
    return {
      siteUrl: this.siteUrl,
      brandNameFull: this.brandNameFull,
      logoVersion: this.logoVersion,
      urlPrefix: environment.sitePrefix,
      apiPrefix: environment.apiPrefix,
    };
  }

  get mockApiUrl() {
    if (environment.isMockEnabled && MHLU_BRANDS.includes(this.brand)) {
      return `https://${environment.mockApiPrefix}mockserviceapi.mediahuis.${
        this.brand === BRANDS.APORTAL ? 'intern' : 'lu'
      }${
        environment.isLocal ? ':4443' : ''
      }/b2c/subscriptionmanagement-mhlu/v1/api`;
    }
    return null;
  }
  get kbaHelperUrl() {
    return `https://${
      environment.isLocal || environment.isDev ? 'test' : environment.apiPrefix
    }kba-helper.mediahuis.com`;
  }
}

const instance = new ConfigService();
export { instance as config };
