import { config } from '~/services';
import { apiCall } from '~/utils';
import { addLeadingZeroes } from '~/utils/addLeadingZeroes';

const getOutstandingPayment = ({ agreementId }) => {
  return apiCall(
    `${
      config.outstandingPaymentApiUrl
    }/OrderSettlements?agreementId=${addLeadingZeroes(
      agreementId,
    )}&api-version=2.0`,
    {
      method: 'GET',
      onSuccess: response => response,
      onError: error => error,
    },
  );
};
export default getOutstandingPayment;
